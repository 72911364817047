import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';

import { getContactDataAction } from '../../state/actions';

import HeadStuff from '../../components/head-stuff/';
import mapStyle from './map-style';
import markerUrl from './marker.png';
import './contact.css';

import { trackPage } from '../../util';

const latLng = {lat: 50.719892, lng: -1.887407};

class Contact extends Component {
	tracked = false;
	render() {
		return (
			<div className="contact">
				<HeadStuff post={this.props.content}/>
				<div style={{ height: '100vh', width: '100%' }}>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: 'AIzaSyBhCmu027wO-OV7DJ5tV-oSr3vpwDPbkOk'
						}}
						defaultCenter={latLng}
						defaultZoom={7}
						options={{
							disableDefaultUI: true,
							styles: mapStyle
						}}
					>
						<Marker {...latLng} />
					</GoogleMapReact>
				</div>
				<div className="contact-details">
					<p className="contact-details__phone">03330 065 556</p>
					<p className="contact-details__email"><a href="mailto:hello@discoverhuman.co.uk">hello@discoverhuman.co.uk</a></p>
					<p>Discover Human Limited<br/>28 Poole Hill<br/>Bournemouth<br/>BH2 5PS<br/>United Kingdom</p>
				</div>
			</div>
		);
	}
	componentDidMount() {
		this.props.getAboutData();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.content) {
			trackPage(this.props.content.title.rendered);
			this.tracked = true;
		}
	}
};

const Marker = () => {
	return <div className="contact-marker" text="Marker">
		<img src={markerUrl} alt=""/>
	</div>;
};

const ContactWrapper = props => <Contact {...props}></Contact>

export default connect(
	state => ({
		content: state.contactContent
	}),
	dispatch => ({
		getAboutData: () => {
			dispatch(getContactDataAction());
		}
	})
)(ContactWrapper);
