import React from 'react';
import ArrowIcon from 'react-icons/lib/md/arrow-back';
import { Link } from 'react-router-dom';
import './Arrow.css';

const Arrow = props => (
    <Link to={props.link} className="content-arrow"><ArrowIcon></ArrowIcon> Back</Link>
);

export default Arrow;
