const initState = {
	navOpen: false,
	brandsContent: null,
	workContent: null,
	projects: [],
	posts: [],
	tags: [],
	blogContent: null,
	loading: false,
	approachContent: null,
	servicesContent: null,
	teamContent: null,
	aboutContent: null,
	designWorkshopContent: null,
	businessWorkshopContent: null,
	contactContent: null,
	homeContent: null,
	kanjoContent: null
};

const rootReducer = (state = initState, action) => {
	let newState = {...state};

	switch (action.type) {
		case 'SHOW_NAV':
			newState.navOpen = true;
			return newState;
		case 'HIDE_NAV':
			newState.navOpen = false;
			return newState;
		case 'SET_BRANDS':
			newState.brandsContent = action.data;
			return newState;
		case 'SET_WORK':
			newState.workContent = action.content;
			newState.projects = action.projects;
			return newState;
		case 'SET_POSTS':
			newState.posts = action.posts;
			newState.tags = action.tags;
			newState.blogContent = action.blog;
			return newState;
		case 'SET_LOADING':
			newState.loading = action.isLoading;
			return newState;
		case 'SET_APPROACH_DATA':
			newState.approachContent = action.data;
			return newState;
		case 'SET_SERVICES_DATA':
			newState.servicesContent = action.data;
			return newState;
		case 'SET_TEAM_DATA':
			newState.teamContent = action.data;
			return newState;
		case 'SET_ABOUT_DATA':
			newState.aboutContent = action.data;
			return newState;
		case 'SET_DESIGN_WORKSHOP_DATA':
			newState.designWorkshopContent = action.data;
			return newState;
		case 'SET_BUSINESS_WORKSHOP_DATA':
			newState.businessWorkshopContent = action.data;
			return newState;
		case 'SET_CONTACT_DATA':
			newState.contactContent = action.data;
			return newState;
		case 'SET_HOME_DATA':
			newState.homeContent = action.data;
			return newState;
		case 'SET_KANJO_DATA':
			newState.kanjoContent = action.data;
			return newState;
		default:
			return state;
	}
};

export default rootReducer;
