import React, { Component } from 'react';
import Cross from 'react-icons/lib/io/ios-close-empty';
import { hideNavAction, showNavAction } from '../../state/actions';
import { connect } from 'react-redux';
import StaggerAnim from '../stagger-anim/';
import { Link } from 'react-router-dom';
import './Nav.css';

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPeak: false,
			mainLinks: [
				{label: 'Intro', href: '/'},
				{label: 'About', href: '/about'},
				{label: 'Services', href: '/services'},
				{label: 'Brands', href: '/brands'},
				{label: 'Work', href: '/work'},
				{label: 'Team', href: '/team'},
				{label: 'Blog', href: '/blog'},
				{label: 'Contact', href: '/contact'}
			]
		};
		this.animTimer = null;
	}
	showPeak = () => {
		this.setState({showPeak: true})
	}
	hidePeak = () => {
		this.setState({showPeak: false})
	}
	render() {
		let navClasses = ['nav', this.props.navOpen ? 'nav--open' : 'nav--closed'];
		if(this.state.showPeak) navClasses.push('nav--show-peak');

		let mainItems = this.state.mainLinks.map(link => <li className="nav__main-item" key={link.label}><Link to={link.href} onClick={this.props.hideNav}>{link.label}</Link></li>);
		let mainItemsAnim = this.props.navOpen ? <StaggerAnim name="nav" delay={60}>{mainItems}</StaggerAnim> : null;

		let extraItems = null;

		if(this.props.navOpen) {
			extraItems = <StaggerAnim name="nav" initialDelay={650}>
				<li className="nav__extra-item"><a target="_blank" href="/Human_Privacy_Policy.pdf">Privacy Policy</a></li>
				<li className="nav__extra-item"><a target="_blank" href="/Human_Terms_and_Conditions.pdf">Terms &amp; Conditions</a></li>
			</StaggerAnim>
		}

		return (
			<div className={navClasses.join(' ')}>
				<div className="nav__bg"></div>
				<div className="nav__btn"
					onMouseEnter={this.showPeak}
					onMouseLeave={this.hidePeak}
					onClick={this.props.showNav}>Discover</div>
				<div className="nav__menu">
					<div className="nav__inner">
						<ul className="nav__main">
							{mainItemsAnim}
						</ul>
						<ul className="nav__extra">
							{extraItems}
						</ul>
					</div>
					<Cross className="nav__close" color="#fff" size="45" onClick={this.props.hideNav}></Cross>
				</div>
			</div>
		);
	}
}

const NavWrapper = props => <Nav {...props}></Nav>

export default connect(
	state => ({
		navOpen: state.navOpen
	}),
	dispatch => ({
		hideNav: () => {
			dispatch(hideNavAction());
		},
		showNav: () => {
			dispatch(showNavAction());
		}
	})
)(NavWrapper);
