import React, { Component } from 'react';
import Card from './Card';

import './Blog.css';

class Recommend extends Component {
	render() {
		return (
			<section className="rec">
				<div className="rec__inner">
					<h2>We thought you also might like to read these</h2>
					<div className="rec__cards">
						{this.props.posts.map((post, index) => <Card key={index} tags={this.props.tags} post={post} />)}
					</div>
				</div>
			</section>
		);
	}
}

export default Recommend;
