import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBusinessWorkshopDataAction } from '../../state/actions';

import Content from '../../components/content/';
import GradientBar from '../../components/gradient-bar/';
import HeadStuff from '../../components/head-stuff/';
import WorkshopForm from './WorkshopForm'

import { trackPage } from '../../util';
 
class Business extends Component {
	tracked = false;
	render() {
		let data = this.props.content;
		let copy1 = '';
		let copy2 = '';

		if (data !== null) {
			let parts = data.acf.content.split('<p>{{form}}</p>');
			copy1 = parts[0];
			if (parts.length > 1) {
				copy2 = parts[1];
			}
		}

		let heading = data && data.acf.heading ? data.acf.heading : '';

		return (
			<Content>
				<HeadStuff post={data}/>
				<GradientBar/>
				<h1 dangerouslySetInnerHTML={{__html: heading}}></h1>
				<div dangerouslySetInnerHTML={{__html: copy1}}></div>
				<WorkshopForm source="Business growth"/>
				<div dangerouslySetInnerHTML={{__html: copy2}}></div>
			</Content>
		);
	}
	componentDidMount() {
		this.props.getData();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.content) {
			trackPage(this.props.content.title.rendered);
			this.tracked = true;
		}
	}
}

const BusinessWrapper = props => <Business {...props}></Business>

export default connect(
	state => ({
		content: state.businessWorkshopContent
	}),
	dispatch => ({
		getData: () => {
			dispatch(getBusinessWorkshopDataAction());
		}
	})
)(BusinessWrapper);
