import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StaggerAnim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: 'start',
        };
        this.timer = null;
    }
    addClass = (child, index) => {
        let oldClasses = child.props.className || '';
        let newClasses = oldClasses + ' ' + this.props.name + '-anim-' + this.state.stage;
        let props = {...child.props};
        props.className = newClasses;
        props.style = {
            ...props.style,
            transitionDelay: (index * this.props.delay) + 'ms',
            transitionDuration: this.props.duration
        };
        return React.cloneElement(child, props);
    }
    render() {
        return React.Children.map(this.props.children, (child, index) => this.addClass(child, index));
    }
    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({stage: 'end'})
        }, this.props.initialDelay);
    }
    componentWillUnmount() {
        if(this.timer) clearTimeout(this.timer);
    }
};

StaggerAnim.propTypes = {
    name: PropTypes.string.isRequired,
    initialDelay: PropTypes.number,
    delay: PropTypes.number,
    duration: PropTypes.string
};

StaggerAnim.defaultProps = {
    initialDelay: 10,
    delay: 100,
    duration: '.5s'
};

export default StaggerAnim;
