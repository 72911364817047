import React, { Component } from 'react';

import './workshop.css';

class WorkshopForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			inputName: '',
			inputCompany: '',
			inputEmail: '',
			inputPhone: '',
			inputPostcode: '',
			inputOther: '',
			finished: false,
		};
	}
	processing = event => {
		event.preventDefault();
		this.setState({processing: true}, this.submit.bind(this));
	}
	async submit() {
		let data = {
			name: this.state.inputName,
			company: this.state.inputCompany,
			email: this.state.inputEmail,
			phone: this.state.inputPhone,
			postcode: this.state.inputPostcode,
			other: this.state.inputOther,
			source: this.props.source || ''
		};

		let response = await fetch(process.env.REACT_APP_WORKSHOP, {
			method: 'POST',
			headers: {'Content-Type': 'text/plain'},
			body: JSON.stringify(data),
			mode: 'cors'
		});

		if (response.ok) {
			this.setState({finished: true, processing: false});
		} else {
			console.log(response);
			alert('Something went wrong, please try again later.');
			this.setState({processing: false});
		}
	}
	onInputChange = event => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({[name]: value});
	}
	render() {
		let submitBtnLabel = this.state.processing ? 'Processing...' : 'Submit';

		let formReady =
			this.state.inputName.length > 0 &&
			this.state.inputCompany.length > 0 &&
			this.state.inputEmail.length > 0 &&
			this.state.inputEmail.indexOf('@') !== -1 &&
			this.state.inputPostcode.length > 0
		;

		if (this.state.finished) {
			return <div className="workshop-form-success">Thank you, we'll be in touch soon.</div>;
		}

		return (
			<form className="workshop-form">
				<div className="input-wrap required">
					<label htmlFor="input-name">Name</label>
					<input type="text" id="input-name" name="inputName" value={this.state.inputName} onChange={this.onInputChange} required/>
				</div>
				<div className="input-wrap required">
					<label htmlFor="input-name">Company</label>
					<input type="text" id="input-company" name="inputCompany" value={this.state.inputCompany} onChange={this.onInputChange} required/>
				</div>
				<div className="input-wrap required">
					<label htmlFor="input-email">Email</label>
					<input type="email" id="input-email" name="inputEmail" value={this.state.inputEmail} onChange={this.onInputChange} required/>
				</div>
				<div className="input-wrap">
					<label htmlFor="input-phone">Phone</label>
					<input type="text" id="input-phone" name="inputPhone" value={this.state.inputPhone} onChange={this.onInputChange}/>
				</div>
				<div className="input-wrap required">
					<label htmlFor="input-postcode">Postcode</label>
					<input type="text" id="input-postcode" name="inputPostcode" value={this.state.inputPostcode} onChange={this.onInputChange} required/>
				</div>
				<div className="input-wrap">
					<label htmlFor="input-other">Any other relevant information</label>
					<textarea id="input-other" name="inputOther" value={this.state.inputOther} onChange={this.onInputChange}></textarea>
				</div>
				<div className="input-wrap">
					<button type="submit" disabled={!formReady || this.state.processing} onClick={this.processing.bind(this)}>{submitBtnLabel}</button>
				</div>
			</form>
		);
	}
}

export default WorkshopForm;
