import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hammer from 'hammerjs';
import './full-swipe.css';
import debounce from 'lodash.debounce';

class FullSwipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 0,
            numScreens: props.numScreens,
            dragDistance: 0,
            dragging: false
        }
        this.wrapper = React.createRef();
        this.debouncedProgress = debounce(this.progress, 100, {leading: true, trailing: false});
    }
    onSwipe = event => {
        this.progress(event.type === 'swipeup');
    }
    startDragging = event => {
        this.setState({dragging: true, dragDistance: 0});
    }
    stopDragging = event => {
        this.setState({dragging: false});
    }
    onPan = event => {
        const dist = event.type === 'pandown' ? event.distance : event.distance * -1;
        this.setState({dragDistance: dist});
    }
    onMouseWheel = event => {
        if(event.deltaY > 10) {
            this.debouncedProgress(true);
        } else if (event.deltaY < -10) {
            this.debouncedProgress(false);
        }
    }
    progress = (forwards = true) => {
        const newScreen = forwards
            ? this.state.currentScreen + 1
            : this.state.currentScreen - 1;
        if(newScreen > this.state.numScreens - 1 || newScreen < 0) return;

        this.setState({currentScreen: newScreen, dragging: false});

        if(typeof this.props.onProgress === 'function') this.props.onProgress(newScreen);
    }
    render() {
        // iOS safari window.innerHeight is very inconsistent
        // Measuring a 100vh element is more accurate
        // Fallback to windowHeight for first time rendering
        const testEl = document.querySelector('.team-member');
        const windowHeight = testEl ? testEl.offsetHeight : window.innerHeight;
        let yPos = this.state.currentScreen * windowHeight;
        yPos *= -1; // make negative

        if(this.state.dragging) yPos += this.state.dragDistance;

        const style = {
            transform: `translate3D(0, ${yPos}px, 0)`
        };

        let classes = 'full-swipe';
        if(this.state.dragging) classes += ' dragging';

        return (
            <div className="full-swipe-wrap" ref={this.wrapper}>
                <div className={classes} style={style}>
                    {this.props.children}
                </div>
            </div>
        );
    }
    componentDidMount() {
        document.body.classList.add('full-swipe-body');

        var gestures = new Hammer(this.wrapper.current);

        gestures.get('swipe').set({direction: Hammer.DIRECTION_VERTICAL});
        gestures.get('pan').set({direction: Hammer.DIRECTION_VERTICAL});
        gestures.on('swipeup swipedown', this.onSwipe);
        gestures.on('panup pandown', this.onPan);
        gestures.on('panstart', this.startDragging);
        gestures.on('panend', this.stopDragging);

        window.addEventListener('wheel', this.onMouseWheel);

        // trigger progress event for first screen
        // timer needed to force browser repaint or css transitions
        // might not trigger
        setTimeout(() => {
            if(typeof this.props.onProgress === 'function') {
                this.props.onProgress(0);
            }
        }, 10);
    }
    componentWillUnmount() {
        document.body.classList.remove('full-swipe-body');
        window.removeEventListener('wheel', this.onMouseWheel);
    }
}

FullSwipe.propTypes = {
    numScreens: PropTypes.number.isRequired,
    onProgress: PropTypes.func
};

export default FullSwipe;
