import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AnimatedRoute } from 'react-router-transition';

import { getPostsAction } from '../../state/actions';

import StaggerAnim from '../../components/stagger-anim/';
import TwoSlides from '../../components/two-slides/';
import GradientBar from '../../components/gradient-bar/';
import HeadStuff from '../../components/head-stuff/';
import Post from './Post';
import Card from './Card';

import { trackPage } from '../../util';

class Blog extends Component {
	tracked = false;
	render() {
		// check if url is directed at an individual post
		// e.g. /post/{slug}/{post-ID}
		let screenPosition = /\/blog\/[\w-_]+\/\d+/.test(this.props.location.pathname)
			? 'right'
			: 'left';

		let posts = this.props.posts.map(post => <Card post={post} tags={this.props.tags} key={post.id}></Card>);
		let wrap = null;

		if(this.props.posts.length > 0) {
			wrap = <StaggerAnim name="blog" delay={65} initialDelay={200}>{posts}</StaggerAnim>;
		}

		let post = <AnimatedRoute
			path="/blog/:slug/:id"
			component={Post}
			atEnter={{opacity: 0}}
			atLeave={{opacity: 0}}
			atActive={{opacity: 1}}
			mapStyles={(styles) => ({opacity: styles.opacity})}
		/>

		let rightContent = (
			<React.Fragment>
				{post}
			</React.Fragment>
		);
		
		let leftContent = (
			<div className="blog">
				<HeadStuff post={this.props.blogContent}/>
				<h1>Blog</h1>
				<div className="blog__items">
					{wrap}
				</div>
			</div>
		);

		return (
			<React.Fragment>
				<GradientBar colorRGB={[244, 244, 244]}></GradientBar>
				<TwoSlides
					position={screenPosition}
					leftContent={leftContent}
					rightContent={rightContent}
				/>
			</React.Fragment>
		);
	}
	componentDidMount() {
		this.props.getPosts();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.blogContent) {
			trackPage(this.props.blogContent.title.rendered);
			this.tracked = true;
		}
	}
}

const BlogWrapper = props => <Blog {...props}></Blog>

export default connect(
	state => ({
		posts: state.posts,
		tags: state.tags,
		blogContent: state.blogContent
	}),
	dispatch => ({
		getPosts: () => {
			dispatch(getPostsAction());
		}
	})
)(BlogWrapper);