import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getWorkAction } from '../../state/actions';

import Content from '../../components/content';
import HeadStuff from '../../components/head-stuff/';

import './project.css';

import { trackPage } from '../../util';

class Project extends Component {
	tracked = false;
	render() {
		let paramId = parseInt(this.props.match.params.id, 10);
		let { projects } = this.props;
		let project = projects.find(project => project.id === paramId);
		let content;

		if(!!project) {
			let hasLink = !!project.acf.project_link && !!project.acf.project_link_label;
			let link = hasLink
				? <div className="project__link"><a href={project.acf.project_link} target="_blank" rel="noopener noreferrer">{project.acf.project_link_label}</a></div>
				: null;

			content = (
				<Content backLink="/work">
					<HeadStuff post={project} />
					<h1>{project.acf.heading}</h1>
					<p className="project__type">{project.acf.project_type}</p>
					<p className="project__role">{project.acf.project_role}</p>
					<div dangerouslySetInnerHTML={{__html: project.acf.content}}></div>
					{link}
				</Content>
			);
		} else {
			content = <Content backLink="/work" color="light" />;
		}

		return (
			<div className="project">{content}</div>
		);
	}
	componentDidMount() {
		this.props.getWork();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		let paramId = parseInt(this.props.match.params.id, 10);
		let project = this.props.projects.find(project => project.id === paramId);
		if (!this.tracked && project) {
			trackPage(project.title.rendered);
			this.tracked = true;
		}
	}
}

const ProjectWrapper = props => <Project {...props}></Project>

export default connect(
	state => ({
		projects: state.projects
	}),
	dispatch => ({
		getWork: () => {
			dispatch(getWorkAction());
		}
	})
)(ProjectWrapper);
