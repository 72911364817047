import React from 'react';
import { Helmet } from 'react-helmet';

const HeadStuff = props => {
	const acf = props.post && props.post.acf ? props.post.acf : {};

	return (
		<Helmet>
			{acf.title_tag && <title>{acf.title_tag}</title>}
			{<meta name="description" content={acf.meta_description || ''} />}
		</Helmet>
	);
}

export default HeadStuff;