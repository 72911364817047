import React, { Component } from 'react';
import { connect } from 'react-redux';

import { showNavAction, getHomeDataAction } from '../../state/actions';

import Logo from '../../components/logo/';
import HeadStuff from '../../components/head-stuff/';

import './Home.css';
import arrow from '../../static/arrow-right-white.png';
import wirehive from '../../static/wirehive.png';

import { trackPage } from '../../util';

class Home extends Component {
	tracked = false;
	constructor(props) {
		super(props);

		this.state = {
			lines: [
				'We are a human experience agency.',
				'We focus on strengthening connections between people & technology.',
				'We put people before tech. And results before beauty.'
			],
			lastLine: 'At Human we partner with brands to produce and optimise digital products that result in positive emotions & measurable business growth.',
			wordsMarkupList: []
		};

		this.timers = [];

		// split lines into words and letters
		this.state.lines.forEach(line => {
			let wordsList = line.split(' ');
			let wordsMarkupList = wordsList.map(word => {
				let letters = word.replace(/([^\s])/g, '<span class="letter">$&</span>');
				return `<span class="word">${letters}</span> `;
			});

			this.state.wordsMarkupList.push(wordsMarkupList);
		});
	}
	// TODO: React-ify following logic / dom manipulations
	animate = (index, show) => {
		let texts = document.querySelectorAll('.home-text');
		texts[index].style.opacity = 1;
		let letters = texts[index].querySelectorAll('.letter');

		for (let i = 0; i < letters.length; i++) {
			this.timers.push(setTimeout(function(span) {
				if (show) {
					span.classList.add('show');
				} else {
					span.classList.remove('show');
				}
			}.bind(null, letters[i]), 50*i));
		}
	}
	render() {
		return (
			<div className="home">
				<HeadStuff post={this.props.post}/>
				<div className="home-inner">
					<div className="home-all-text">
						<div className="home-text" dangerouslySetInnerHTML={{__html: this.state.wordsMarkupList[0].join(' ')}}></div>
						<div className="home-text" dangerouslySetInnerHTML={{__html: this.state.wordsMarkupList[1].join(' ')}}></div>
						<div className="home-text" dangerouslySetInnerHTML={{__html: this.state.wordsMarkupList[2].join(' ')}}></div>
						<div className="home-text last">{this.state.lastLine}</div>
					</div>
					<div className="home-cta-wrap">
						<span className="home-cta" onClick={this.props.showNav}>Discover Human <img src={arrow} alt=""/></span>
						<Logo className="home-logo"></Logo>
					</div>
				</div>
				<div className="home-wirehive"><img src={wirehive} alt="Wirehive award badge"/></div>
			</div>
		);
	}
	componentDidMount() {
		// 1 in
		this.timers.push(setTimeout(() => this.animate(0, true), 200));

		// 1 out / 2 in
		this.timers.push(setTimeout(() => this.animate(0, false), 5000));
		this.timers.push(setTimeout(() => this.animate(1, true), window.innerWidth <= 680 ? 4050 : 4150));

		// 2 out / 3 in
		this.timers.push(setTimeout(() => this.animate(1, false), 9000));
		this.timers.push(setTimeout(() => this.animate(2, true), window.innerWidth <= 680 ? 10200 : 9800));

		// 3 out / final in
		this.timers.push(setTimeout(() => this.animate(2, false), 15000));
		this.timers.push(setTimeout(() => {
			document.querySelector('.home-text.last').classList.add('show');
			document.querySelector('.home-cta-wrap').classList.add('switch');
		}, 17600));

		// nastily hide main logo
		document.querySelector('.main-logo__wrap').classList.add('hide');

		this.props.getContent();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	componentWillUnmount() {
		this.timers.forEach(timer => {
			if (timer) clearTimeout(timer);
		});

		document.querySelector('.main-logo__wrap').classList.remove('hide');
	}
	track = () => {
		if (!this.tracked && this.props.post) {
			trackPage(this.props.post.title.rendered);
			this.tracked = true;
		}
	}
}

const HomeWrapper = props => <Home {...props}></Home>

export default connect(
	state => ({
		post: state.homeContent
	}),
	dispatch => ({
		showNav: () => {
			dispatch(showNavAction());
		},
		getContent: () => {
			dispatch(getHomeDataAction());
		}
	})
)(HomeWrapper);
