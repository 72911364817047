import arrow from './static/arrow-right-pink.png';

export const stringToSlug = str => {
	if(typeof str !== 'string') {
		throw new Error('str must be a string.');
	}

	// remove all characters not a space or a letter
	let slug = str.replace(/[^\w\s]/gi, '');

	// replace spaces with hyphens
	slug = slug.replace(/\s/g, '-');

	return slug.toLowerCase();
};

export const trackPage = pageTitle => {
	if (typeof window.gtag === 'function') {
		window.gtag('config', 'UA-76289729-2', {
			'page_title': pageTitle,
			'page_path': window.location.pathname
		});
	} else if (typeof window.dataLayer !== 'undefined') {
		window.dataLayer.push({
			event: 'page_view',
			page_title: pageTitle,
			page_path: window.location.pathname
		});
	}
};

export const replaceArrow = content => {
	if (!content) return content;
	return content.replace('{{arrow}}', `<img class="link-arrow" src="${arrow}" alt="">`);
}

export const wpDateToDate = date => {
	let dateParts = /(\d+)-(\d+)-(\d+)/.exec(date);
	return `${dateParts[3]}/${dateParts[2]}/${dateParts[1]}`;
}
