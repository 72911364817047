import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getTeamDataAction } from '../../state/actions';

import FullSwipe from '../../components/full-swipe';
import HeadStuff from '../../components/head-stuff/';

import './team.css';
import adamImg from './img/adam-compressed.png';
import andyImg from './img/andy-compressed.png';
import adeImg from './img/ade-compressed.png';
import claireImg from './img/claire-compressed.png';
import chairImg from './img/chair-compressed.png';
import knightSvg from './img/knight.svg';
import cricketSvg from './img/cricket.svg';
import dataSvg from './img/data.svg';
import ballSvg from './img/ball.svg';
import scrollIconArrow from '../../static/scroll-down-icon-arrow.png';

import { trackPage } from '../../util';

class Team extends Component {
	tracked = false;
	constructor(props) {
		super(props);
		this.state = {
			screens: [
				{visible: false},
				{visible: false},
				{visible: false},
				{visible: false}
			],
			activeScreen: 0,
			showMobileBg: false,
			mobileActiveContent: -1,
			showGfx: false,
			showScrollIcon: true
		};
		this.spotsRefs = [
			React.createRef(),
			React.createRef(),
			React.createRef(),
			React.createRef()
		];
		this.mobileBgRef = React.createRef();
	}
	onScreenChange = screenNum => {
		let newState = {...this.state};
		newState.screens[screenNum].visible = true;
		newState.activeScreen = screenNum;
		if(screenNum > 0) newState.showScrollIcon = false;
		this.setState(newState);
	}
	openMobileDescription = index => {
		this.setState({showMobileBg: true, mobileActiveContent: index});
	}
	closeMobileDescription = () => {
		this.setState({showMobileBg: false, mobileActiveContent: -1});
	}
	render() {
		const { teamContent } = this.props;
		let acf = !!teamContent && !!teamContent.acf ? teamContent.acf : null;

		let bgClasses = ['team-mobile-bg'];
		if(this.state.showMobileBg) bgClasses.push('open');

		let gfxImgs = [chairImg, andyImg, adamImg, claireImg, adeImg];
		let svgs = [null, cricketSvg, knightSvg, ballSvg, dataSvg];

		let gfxMarkup = gfxImgs.map((personImg, index) => {
			const classNamesBase = this.state.activeScreen + 1 === index
				? `team-gfx__img team-gfx__img--${index} active`
				: `team-gfx__img team-gfx__img--${index}`;

			const classNamesDrawing = this.state.activeScreen + 1 === index
				? `team-gfx__svg team-gfx__svg--${index} active`
				: `team-gfx__svg team-gfx__svg--${index}`;

			const classNamesSpot = this.state.activeScreen + 1 === index
				? `team-gfx__spot team-gfx__spot--${index} active`
				: `team-gfx__spot team-gfx__spot--${index}`;

			let hotspot = null;

			if (index > 0) {
				hotspot = <span
					ref={this.spotsRefs[0]}
					className={classNamesSpot}
					onClick={() => this.openMobileDescription(index - 1)}
				/>;
			}

			return (
				<React.Fragment key={index}>
					<img
						className={classNamesBase}
						src={personImg}
						aria-hidden="true"
						alt=""
					/>
					<img
						className={classNamesDrawing}
						src={svgs[index]}
						aria-hidden="true"
						alt=""
					/>
					{hotspot}
				</React.Fragment>
			);
		});

		const desc1 = acf ? acf.andy_description : '';
		const desc2 = acf ? acf.adam_description : '';
		const desc3 = acf ? acf.claire_description : '';
		const desc4 = acf ? acf.ade_description : '';

		const mobileDesc1Classes = 'team-mobile-desc' + (this.state.mobileActiveContent === 0 ? ' active' : '');
		const mobileDesc2Classes = 'team-mobile-desc' + (this.state.mobileActiveContent === 1 ? ' active' : '');
		const mobileDesc3Classes = 'team-mobile-desc' + (this.state.mobileActiveContent === 2 ? ' active' : '');
		const mobileDesc4Classes = 'team-mobile-desc' + (this.state.mobileActiveContent === 3 ? ' active' : '');

		let teamGfxClasses = ['team-gfx'];
		teamGfxClasses.push(`active-${this.state.activeScreen + 1}`);
		if(this.state.showGfx) teamGfxClasses.push('show');

		return (
			<div>
				<HeadStuff post={teamContent}/>
				<FullSwipe numScreens={4} onProgress={this.onScreenChange}>
					<div className={`team-member ${this.state.screens[0].visible ? 'visible' : ''}`}>
						<div className="team-member__inner">
							<h2>Andy Milsom</h2>
							<h3>Founder & Creative Director</h3>
							<p className="team-member__desc">{desc1}</p>
						</div>
					</div>
					<div className={`team-member ${this.state.screens[1].visible ? 'visible' : ''}`}>
						<div className="team-member__inner">
							<h2>Adam Jaggard</h2>
							<h3>Lead Front End Developer</h3>
							<p className="team-member__desc">{desc2}</p>
						</div>
					</div>
					<div className={`team-member ${this.state.screens[2].visible ? 'visible' : ''}`}>
						<div className="team-member__inner">
							<h2>Claire Heydon</h2>
							<h3>Behavioural Analyst</h3>
							<p className="team-member__desc">{desc3}</p>
						</div>
					</div>
					<div className={`team-member ${this.state.screens[3].visible ? 'visible' : ''}`}>
						<div className="team-member__inner">
							<h2>Adrian Durow</h2>
							<h3>CRO Partner</h3>
							<p className="team-member__desc">{desc4}</p>
						</div>
					</div>
				</FullSwipe>

				<div className={teamGfxClasses.join(' ')}>
					{gfxMarkup}
				</div>

				{this.state.showScrollIcon && <img className="team-scroll-icon" src={scrollIconArrow} alt="Scroll down arrow and icon"/>}

				<div ref={this.mobileBgRef} className={bgClasses.join(' ')}>
					<div className="team-mobile-bg__close" onClick={this.closeMobileDescription}></div>
				</div>

				<div className={mobileDesc1Classes}>{desc1}</div>
				<div className={mobileDesc2Classes}>{desc2}</div>
				<div className={mobileDesc3Classes}>{desc3}</div>
				<div className={mobileDesc4Classes}>{desc4}</div>
			</div>
		);
	}
	componentDidMount = () => {
		this.props.getTeamContent();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.teamContent) {
			trackPage(this.props.teamContent.title.rendered);
			this.tracked = true;
		}
	}
	static getDerivedStateFromProps = (props, state) => {
		if(!state.showGfx && props.teamContent) {
			return {showGfx: true};
		} else {
			return null;
		}
	}
}

const TeamWrapper = props => <Team {...props}></Team>

export default connect(
	state => ({
		teamContent: state.teamContent
	}),
	dispatch => ({
		getTeamContent: () => {
			dispatch(getTeamDataAction())
		}
	})
)(TeamWrapper);
