import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './Blog.css';

import { stringToSlug, wpDateToDate } from '../../util';

class Card extends Component {
	render() {
		const post = this.props.post;
		let date = wpDateToDate(post.date);
		let tags = post.tags.map((tagID, index) => {
			let tag = this.props.tags.find(tag => tag.id === tagID);
			if (!tag) return null;
			return <span key={index} className="blog__tag">{tag.name} <span className="pipe">|</span> </span>;
		});
		return (
			<Link to={`/blog/${stringToSlug(post.title.rendered)}/${post.id}`} key={post.id} className="blog__item">
				<div className="blog__thumb-wrap"><img className="blog__thumb" src={post.acf.grid_thumnail.url} alt={post.acf.grid_thumnail.alt}/></div>
				<div className="blog__details">
					<p className="blog__title">{post.title.rendered}</p>
					<p className="blog__date">{date}</p>
					<p className="blog__desc">{post.acf.grid_description}</p>
					<p className="blog__tags">{tags}</p>
				</div>
			</Link>
		);
	}
}

export default Card;
