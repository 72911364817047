import React from 'react';
import PropTypes from 'prop-types';

import './GradientBar.css';

const GradientBar = props => {
    const { colorRGB } = props;
    const startColor = `rgba(${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}, 1)`;
    const endColor = `rgba(${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}, 0)`;
    const gradient = `linear-gradient(to bottom, ${startColor} 30%, ${endColor})`;
    const style = {
        background: gradient
    };

    return (
        <div style={style} className="gradient-bar"></div>
    );
};

GradientBar.defaultProps = {
  colorRGB: [244, 244, 244]
};

GradientBar.propTypes = {
    colorRGB: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default GradientBar;
