import React from 'react';
import PropTypes from 'prop-types';

import './two-slides.css';

function TwoSlides(props) {
    let { position, leftContent, rightContent } = props;
    let classes = ['two-slides', `two-slides--${position}`];
    return (
        <div className={classes.join(' ')}>
            <div className="two-slides__screens">
                <div className="two-slides__screen left">
                    {leftContent}
                </div>
                <div className="two-slides__screen right">
                    {rightContent}
                </div>
            </div>
        </div>
    );
}

TwoSlides.propTypes = {
    position: PropTypes.oneOf(['left', 'right']).isRequired,
    leftContent: PropTypes.element.isRequired,
    rightContent: PropTypes.element.isRequired
};

TwoSlides.defaultProps = {
    position: 'left'
};

export default TwoSlides;
