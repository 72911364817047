import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBrandsDataAction } from '../../state/actions';

import StaggerAnim from '../../components/stagger-anim/';
import GradientBar from '../../components/gradient-bar/';
import HeadStuff from '../../components/head-stuff/';

import './Brands.css';

import { trackPage } from '../../util';

class Brands extends Component {
	tracked = false;
	render() {
		let post = this.props.post;
		let acf = post ? post.acf : null;
		let logos = acf ? acf.logos : [];

		let logoEls = logos.map(logo =>
			<div key={logo.logo.title} className="brands-logos__item">
				<img src={logo.logo.url} alt="" />
			</div>
		);
		let wrap = null;

		if(logos.length > 1) {
			wrap = <StaggerAnim name="brands" delay={50} initialDelay={150} duration=".7s">{logoEls}</StaggerAnim>
		}

		return (
			<div className="brands">
				<HeadStuff post={post}/>
				<GradientBar colorRGB={[244, 244, 244]}></GradientBar>
				<div className="brands-intro">{acf && acf.side_text}</div>
				<div className="brands-logos">{wrap}</div>
			</div>
		);
	}
	componentDidMount() {
		this.props.getBrandsData();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.post) {
			trackPage(this.props.post.title.rendered);
			this.tracked = true;
		}
	}
}

const BrandsWrapper = props => <Brands {...props}></Brands>

export default connect(
	state => ({
		post: state.brandsContent
	}),
	dispatch => ({
		getBrandsData: () => {
			dispatch(getBrandsDataAction());
		},
	})
)(BrandsWrapper);
