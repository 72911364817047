import React, { Component } from 'react';
import debounce from 'lodash.debounce';

function withBreakpoint(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            };
            this.debouncedStoreDimensions = debounce(this.storeDimensions.bind(this), 100);
        }
        storeDimensions = () => {
            this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight})
        }
        render() {
            return <WrappedComponent {...this.state} {...this.props} />
        }
        componentDidMount() {
            window.addEventListener('resize', this.debouncedStoreDimensions);
        }
        componentWillUnmount() {
            window.removeEventListener('resize', this.debouncedStoreDimensions);
        }
    }
}

export default withBreakpoint;
