import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnimatedRoute } from 'react-router-transition';

import { getWorkAction } from '../../state/actions';

import StaggerAnim from '../../components/stagger-anim/';
import TwoSlides from '../../components/two-slides/';
import GradientBar from '../../components/gradient-bar/';
import HeadStuff from '../../components/head-stuff/';
import Project from './Project';

import { stringToSlug } from '../../util';
import { trackPage } from '../../util';

import './Work.css';

class Work extends Component {
    tracked = false;
    render() {
        // check if url is directed at an individual project
        // e.g. /work/project-name/{project-ID}
        let screenPosition = /\/work\/[\w-_]+\/\d+/.test(this.props.location.pathname)
            ? 'right'
            : 'left';

        let projects = this.props.projects.map(project =>
            <Link to={`/work/${stringToSlug(project.acf.heading)}/${project.id}`} key={project.id} className="work-project">
                <img className="work-project__thumb" src={project.acf.thumbnail.url} alt={project.acf.thumbnail.alt}/>
                <div className="work-project__overlay">
                    <div>
                        <p className="work-project__heading">{project.acf.heading}</p>
                        <p className="work-project__type">{project.acf.project_type}</p>
                        <p className="work-project__role">{project.acf.project_role}</p>
                    </div>
                </div>
            </Link>
        );
        let wrap = null;

        if(this.props.projects.length > 0) {
            wrap = <StaggerAnim name="work" delay={65} initialDelay={200}>{projects}</StaggerAnim>;
        }

        let project = <AnimatedRoute
            path="/work/:slug/:id"
            component={Project}
            atEnter={{opacity: 0}}
            atLeave={{opacity: 0}}
            atActive={{opacity: 1}}
            mapStyles={(styles) => ({opacity: styles.opacity})}
        />

        let rightContent = (
            <React.Fragment>
                <GradientBar colorRGB={[244, 244, 244]}></GradientBar>
                {project}
            </React.Fragment>
        );

        let work = (
            <div className="work">
                <HeadStuff post={this.props.content}/>
                <div className="work-projects">
                    {wrap}
                </div>
            </div>
        );

        return (
            <TwoSlides
                position={screenPosition}
                leftContent={work}
                rightContent={rightContent}
            />
        );
    }
    componentDidMount() {
        this.props.getWork();
        this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.content) {
			trackPage(this.props.content.title.rendered);
			this.tracked = true;
		}
	}
}

const WorkWrapper = props => <Work {...props}></Work>

export default connect(
    state => ({
        content: state.workContent,
        projects: state.projects
    }),
    dispatch => ({
        getWork: () => {
            dispatch(getWorkAction());
        }
    })
)(WorkWrapper);
