import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AnimatedRoute } from 'react-router-transition';

import StaggerAnim from '../../components/stagger-anim/';
import withBreakpoint from '../../components/with-breakpoint/';
import TwoSlides from '../../components/two-slides/';
import GradientBar from '../../components/gradient-bar/';

import './Columns.css';

class Columns extends Component {
	constructor(props) {
		super(props);
		this.videoRef = React.createRef();
	}
	render() {
		let { windowWidth } = this.props;
		let video = windowWidth < 1024
			? null
			: <video ref={this.videoRef} className="columns__bg-video" src={this.props.videoSrc} muted autoPlay loop preload="auto"></video>;

		const columns = this.props.dataReady
			? <StaggerAnim name="columns-column" duration=".9s" initialDelay={500}>
					<Link to={this.props.column1Link} className="columns-item">
						<div className="columns-item__inner">{this.props.column1Content}</div>
					</Link>
					<Link to={this.props.column2Link} className="columns-item">
						<div className="columns-item__inner">{this.props.column2Content}</div>
					</Link>
					<Link to={this.props.column3Link} className="columns-item">
						<div className="columns-item__inner">{this.props.column3Content}</div>
					</Link>
				</StaggerAnim>
			: null;

		const leftContent = (
			<div className="columns" style={{backgroundImage: `url(${this.props.leftBgSrc})`}}>
				{video}
				<div className="columns-items">
					{columns}
				</div>
			</div>
		);

		const animatedRouteAttributes = {
			atEnter: {opacity: 0},
			atLeave: {opacity: 0},
			atActive: {opacity: 1},
			mapStyles: (styles) => ({opacity: styles.opacity})
		};

		const rightContent = (
			<React.Fragment>
				<GradientBar/>
				<div className="columns-sub-content-wrap">
					<div className="columns-sub-content">
						<AnimatedRoute
							path={(`/${this.props.rightRoute}/:sub_content`)}
							component={this.props.rightContent}
							{...animatedRouteAttributes}
						/>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<TwoSlides
				position={this.props.position}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		)
	}
	componentDidUpdate(prevProps) {
		// check if video should be paused when visiting sub content in right slide
		if (this.videoRef.current && this.props.position === 'right') {
			this.videoRef.current.pause();
		} else if(this.videoRef.current) {
			this.videoRef.current.play();
		}
	}
}

export default withBreakpoint(Columns);
