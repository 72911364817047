import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPostsAction } from '../../state/actions';

import Content from '../../components/content';
import HeadStuff from '../../components/head-stuff/';
import Recommend from './Recommend';

import './Blog.css';

import { trackPage, wpDateToDate } from '../../util';

class Post extends Component {
	tracked = false;
	constructor() {
		super();
		this.state = {
			dataLoaded: false
		};
	}
	render() {
		let paramId = parseInt(this.props.match.params.id, 10);
		let { posts } = this.props;
		let post = posts.find(post => post.id === paramId);
		let content;

		if(post) {
			let date = wpDateToDate(post.date);
			let tags = post.tags.map((tagID, index) => {
				let tag = this.props.tags.find(tag => tag.id === tagID);
				if (!tag) return null;
				return <span key={index} className="post__tag">{tag.name}</span>;
			});

			let recommended = null;
			if (post.acf.recommended) {
				recommended = <Recommend tags={this.props.tags} posts={this.props.posts.filter(allPost => {
					return !!post.acf.recommended.find(recPost => recPost.post === allPost.id);
				})}></Recommend>
			}

			content = (
				<React.Fragment>
					<HeadStuff post={post}/>
					<img className="post__hero desktop" src={post.acf.hero.url} alt=""/>
					<img className="post__hero mobile" src={post.acf.hero_mobile.url} alt=""/>
					<Content>
						<h1>{post.title.rendered}</h1>
						<div className="post__author">{post.acf.author}</div>
						<div className="post__date">{date}</div>
						<div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
						<div className="post__tags">{tags}</div>
					</Content>
					{recommended}
				</React.Fragment>
			);
		} else {
			content = <Content color="light" />;
		}

		return (
			<div className="post">{content}</div>
		);
	}
	componentDidMount() {
		this.props.getPosts();
		this.track();
	}
	componentDidUpdate() {
		// TODO: replace shitty hack with props function
		const el = document.querySelector('.two-slides__screen.right');
		if (el) el.scrollTo(0, 0);
		this.track();
	}
	track = () => {
		let paramId = parseInt(this.props.match.params.id, 10);
		let post = this.props.posts.find(post => post.id === paramId);
		if (!this.tracked && post) {
			trackPage(post.title.rendered);
			this.tracked = true;
		}
	}
}

const PostWrapper = props => <Post {...props}></Post>

export default connect(
	state => ({
		posts: state.posts,
		tags: state.tags
	}),
	dispatch => ({
		getPosts: () => {
			dispatch(getPostsAction());
		}
	})
)(PostWrapper);
