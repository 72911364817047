import axios from 'axios';

export const showNavAction = () => ({
	type: 'SHOW_NAV'
});

export const hideNavAction = () => ({
	type: 'HIDE_NAV'
});

export const getBrandsDataAction = () => (dispatch, getState) => {
	let { brandsContent } = getState();

	// no need to get logos again, just causes re-rendering
	if (brandsContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_BRANDS)
		.then(response => {
			dispatch(setBrandsAction(response.data));
			dispatch(setLoadingAction(false));
		})
		.catch(error => {
			dispatch(setLoadingAction(false));
			console.log(error);
		});
};

export const setBrandsAction = data => ({
	type: 'SET_BRANDS',
	data
});

export const getWorkAction = () => (dispatch, getState) => {
	let { projects } = getState();

	// no need to get projects again, just causes re-rendering
	if(projects.length > 0) return;

	dispatch(setLoadingAction(true));

	const getProjects = axios.get(process.env.REACT_APP_API+ 'wp/v2/project?per_page=100');
	const getWork = axios.get(process.env.REACT_APP_API+ 'wp/v2/pages/' + process.env.REACT_APP_ID_WORK);

	Promise.all([getProjects, getWork])
		.then(values => {
			dispatch(setWorkAction({projects: values[0].data, content: values[1].data}));
			dispatch(setLoadingAction(false));
		})
		.catch(error => {
			dispatch(setLoadingAction(false));
			console.log(error);
		});
};

export const setWorkAction = data => ({
	type: 'SET_WORK',
	content: data.content,
	projects: data.projects
});

export const setLoadingAction = isLoading => ({
	type: 'SET_LOADING',
	isLoading
});

export const getServicesDataAction = () => (dispatch, getState) => {
	let { servicesContent } = getState();

	// content already in store
	if (servicesContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_SERVICES)
		.then(response => {
			dispatch(setServicesDataAction(response.data))
			dispatch(setLoadingAction(false));
		})
		.catch(response => {
			dispatch(setLoadingAction(false));
		});
};

export const setServicesDataAction = data => ({
	type: 'SET_SERVICES_DATA',
	data
});

export const getTeamDataAction = () => (dispatch, getState) => {
	let { teamContent } = getState();

	// content already in store
	if(teamContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_TEAM)
		.then(response => {
			dispatch(setTeamDataAction(response.data))
			dispatch(setLoadingAction(false));
		})
		.catch(response => {
			dispatch(setLoadingAction(false));
		});
};

export const setTeamDataAction = data => ({
	type: 'SET_TEAM_DATA',
	data
});

export const getAboutDataAction = () => (dispatch, getState) => {
	let { aboutContent } = getState();

	// content already in store
	if (aboutContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_ABOUT)
		.then(response => {
			dispatch(setAboutDataAction(response.data))
			dispatch(setLoadingAction(false));
		})
		.catch(response => {
			dispatch(setLoadingAction(false));
		});
};

export const setAboutDataAction = data => ({
	type: 'SET_ABOUT_DATA',
	data
});

export const setDesignWorkshopDataAction = data => ({
	type: 'SET_DESIGN_WORKSHOP_DATA',
	data
});

export const getDesignWorkshopDataAction = () => (dispatch, getState) => {
	let { designWorkshopContent } = getState();

	// content already in store
	if (designWorkshopContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_DESIGN_WORKSHOP)
		.then(response => {
			dispatch(setDesignWorkshopDataAction(response.data))
		})
		.finally(response => {
			dispatch(setLoadingAction(false));
		});
};

export const setBusinessWorkshopDataAction = data => ({
	type: 'SET_BUSINESS_WORKSHOP_DATA',
	data
});

export const getBusinessWorkshopDataAction = () => (dispatch, getState) => {
	let { businessWorkshopContent } = getState();

	// content already in store
	if (businessWorkshopContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_BUSINESS_WORKSHOP)
		.then(response => {
			dispatch(setBusinessWorkshopDataAction(response.data))
		})
		.finally(response => {
			dispatch(setLoadingAction(false));
		});
};

export const getPostsAction = () => (dispatch, getState) => {
	let { posts } = getState();

	if (posts.length > 0) return;

	dispatch(setLoadingAction(true));

	let getPosts = axios.get(process.env.REACT_APP_API + 'wp/v2/posts?per_page=100');
	let getTags = axios.get(process.env.REACT_APP_API + 'wp/v2/tags?per_page=100');
	let getBlog = axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_BLOG);

	Promise.all([getPosts, getTags, getBlog])
		.then(values => {
			dispatch(setPostsAction({posts: values[0].data, tags: values[1].data, blog: values[2].data}));
			dispatch(setLoadingAction(false));
		})
		.catch(error => {
			dispatch(setLoadingAction(false));
			console.log(error);
		});
};

export const setPostsAction = data => ({
	type: 'SET_POSTS',
	posts: data.posts,
	tags: data.tags,
	blog: data.blog
});

export const getContactDataAction = () => (dispatch, getState) => {
	let { contactContent } = getState();

	// content already in store
	if (contactContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_CONTACT)
		.then(response => {
			dispatch(setContactDataAction(response.data))
		})
		.finally(response => {
			dispatch(setLoadingAction(false));
		});
};

export const setContactDataAction = data => ({
	type: 'SET_CONTACT_DATA',
	data
});

export const getHomeDataAction = () => (dispatch, getState) => {
	let { homeContent } = getState();

	// content already in store
	if (homeContent) return;

	// The homepage has content mostly hard coded
	// so don't delay showing it
	// dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_HOME)
		.then(response => {
			dispatch(setHomeDataAction(response.data))
		});
};

export const setHomeDataAction = data => ({
	type: 'SET_HOME_DATA',
	data
});

export const getKanjoDataAction = () => (dispatch, getState) => {
	let { kanjoContent } = getState();

	// content already in store
	if (kanjoContent) return;

	dispatch(setLoadingAction(true));

	axios.get(process.env.REACT_APP_API + 'wp/v2/pages/' + process.env.REACT_APP_ID_KANJO)
		.then(response => {
			dispatch(setKanjoDataAction(response.data))
		})
		.catch(error => console.log)
		.finally(response => {
			dispatch(setLoadingAction(false));
		});;
};

export const setKanjoDataAction = data => ({
	type: 'SET_KANJO_DATA',
	data
});
