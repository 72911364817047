import React from 'react';
import PropTypes from 'prop-types';
import Arrow from './Arrow';
import './Content.css';

const Content = props => {
	return (
		<div className="content">
			{props.backLink && <Arrow link={props.backLink}></Arrow>}
			{props.children}
		</div>
	);
};

Content.propTypes = {
	backLink: PropTypes.string
};

export default Content;
