import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from '../home/';
import About from '../about/';
import Brands from '../brands/';
import Work from '../work/';
import Contact from '../contact/';
import Team from '../team/';
import Services from '../services/';
import Blog from '../blog/';
import DesignWorkshop from '../workshop/Design';
import BusinessWorkshop from '../workshop/Business';

import Nav from '../../components/nav/';
import Logo from '../../components/logo/';
import Content from '../../components/content/';
import Loader from './Loader';

import './App.css';

const LogoWithRouter = withRouter(Logo);
const logo = <LogoWithRouter className="main-logo" color="#E81F76" />

const notFound = () => <Content><div className="not-found"><h1>Page not found.</h1></div></Content>;

class App extends Component {
	render() {
		const touchDevice = 'ontouchstart' in document.documentElement;
		let rootClasses = [touchDevice ? 'has-touch' : 'no-touch'];
		return (
			<Router>
				<div className={rootClasses.join(' ')}>
					<Helmet titleTemplate="Human | %s">
						<meta charSet="utf-8" />
					</Helmet>
					<div className="pages">
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/about" component={About} />
							<Route path="/services" component={Services} />
							<Route path="/brands" component={Brands} />
							<Route path="/work" component={Work} />
							<Route path="/team" component={Team} />
							<Route path="/contact" component={Contact} />
							<Route path="/blog" component={Blog} />
							<Route path="/design-sprint-workshop" component={DesignWorkshop} />
							<Route path="/digital-business-growth-workshop" component={BusinessWorkshop} />
							<Route component={notFound} />
						</Switch>
					</div>
					<Loader />
					<Link to="/" className="main-logo__wrap">
						{logo}
					</Link>
					<Nav />
				</div>
			</Router>
		);
	}
}

export default App;
