import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAboutDataAction } from '../../state/actions';

import Content from '../../components/content/';
import GradientBar from '../../components/gradient-bar/';
import HeadStuff from '../../components/head-stuff/';

import { trackPage, replaceArrow } from '../../util';

class About extends Component {
	tracked = false;
	render() {
		let data = this.props.content;
		
		return (
			<Content>
				<HeadStuff post={data}/>
				<GradientBar/>
				<h1>{data && data.acf.heading}</h1>
				<h2 className="content__subheading">{data && data.acf.subheading}</h2>
				<div dangerouslySetInnerHTML={{__html: data && replaceArrow(data.acf.content)}}></div>
			</Content>
		);
	}
	componentDidMount() {
		this.props.getAboutData();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.content) {
			trackPage(this.props.content.title.rendered);
			this.tracked = true;
		}
	}
}

const AboutWrapper = props => <About {...props}></About>

export default connect(
	state => ({
		content: state.aboutContent
	}),
	dispatch => ({
		getAboutData: () => {
			dispatch(getAboutDataAction());
		}
	})
)(AboutWrapper);
