import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getServicesDataAction } from '../../state/actions';

import Columns from '../../components/columns/';
import SubContent from './sub-content/';
import HeadStuff from '../../components/head-stuff/';

import illustration1 from './innovate.svg';
import illustration2 from './create.svg';
import illustration3 from './optimise.svg';
import bgSrc from '../../static/video.jpg';
import videoSrc from '../../static/bg-15fps.mp4';
import arrow from '../../static/arrow-right-black.png';

import './Services.css';

import { trackPage } from '../../util';

class Services extends Component {
	tracked = false;
	getScreenPosition = () => {
		return /\/services\/\w+/.test(this.props.location.pathname) ? 'right' : 'left';
	}
	render() {
		let data = this.props.servicesContent;
		let position = this.getScreenPosition();

		const column1Link = '/services/innovate';
		const column1Content = data
			? <React.Fragment>
				<h2 className="column__heading">
					<img className="column__gfx" src={illustration1} alt="" aria-hidden="true"/>
					<span className="pt1">{data && data.acf.columns[0].name}</span>
					<span className="pt2">{data && data.acf.columns[0].title}</span>
				</h2>
				<p className="column__desc">{data && data.acf.columns[0].description}</p>
				<p className="column__cta"><span>{data && data.acf.columns[0].cta_label}</span> <img src={arrow} alt=""/></p>
			</React.Fragment>
			: null;

		const column2Link = '/services/create';
		const column2Content = data
			? <React.Fragment>
				<h2 className="column__heading">
					<img className="column__gfx" src={illustration2} alt="" aria-hidden="true"/>
					<span className="pt1">{data && data.acf.columns[1].name}</span>
					<span className="pt2">{data && data.acf.columns[1].title}</span>
				</h2>
				<p className="column__desc">{data && data.acf.columns[1].description}</p>
				<p className="column__cta"><span>{data && data.acf.columns[1].cta_label}</span> <img src={arrow} alt=""/></p>
			</React.Fragment>
			: null;

		const column3Link = '/services/optimise';
		const column3Content = data
			? <React.Fragment>
				<h2 className="column__heading">
					<img className="column__gfx" src={illustration3} alt="" aria-hidden="true"/>
					<span className="pt1">{data && data.acf.columns[2].name}</span>
					<span className="pt2">{data && data.acf.columns[2].title}</span>
				</h2>
				<p className="column__desc">{data && data.acf.columns[2].description}</p>
				<p className="column__cta"><span>{data && data.acf.columns[2].cta_label}</span> <img src={arrow} alt=""/></p>
			</React.Fragment>
			: null;

		return (
			<div className="services">
				<HeadStuff post={data}/>
				<Columns
					column1Content={column1Content}
					column1Link={column1Link}
					column2Content={column2Content}
					column2Link={column2Link}
					column3Content={column3Content}
					column3Link={column3Link}
					rightContent={SubContent}
					position={position}
					leftBgSrc={bgSrc}
					videoSrc={videoSrc}
					dataReady={!!data}
					rightRoute="services"
				></Columns>
			</div>
		);
	}
	componentDidMount() {
		this.props.getServicesContent();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		if (!this.tracked && this.props.servicesContent) {
			trackPage(this.props.servicesContent.title.rendered);
			this.tracked = true;
		}
	}
};

const ServicesWrapper = props => <Services {...props}></Services>

export default connect(
	state => ({
		servicesContent: state.servicesContent
	}),
	dispatch => ({
		getServicesContent: () => {
			dispatch(getServicesDataAction())
		}
	})
)(ServicesWrapper);
