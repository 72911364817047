import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { getServicesDataAction } from '../../../state/actions';

import Content from '../../../components/content/';

import arrow from '../../../static/arrow-down-pink.png';

import { trackPage, replaceArrow } from '../../../util';

class SubContent extends Component {
	tracked = false;
	constructor() {
		super();
		this.state = {
			pageTitles: {
				'innovate': {title: 'Innovate', num: 0},
				'create': {title: 'Create', num: 1},
				'optimise': {title: 'Optimise', num: 2}
			}
		};
	}

	render() {
		let data = this.props.content;
		const route = this.props.match.params.sub_content;
		const pageNum = this.state.pageTitles[route] && this.state.pageTitles[route].num;
		var contentPt1 = data && data.acf.columns[pageNum].main_content;
		var contentPt2 = '';

		if (data && contentPt1.indexOf('{{innovation_sprints}}') !== -1) {
			let pts = contentPt1.split('{{innovation_sprints}}');
			contentPt1 = pts[0];
			contentPt2 = pts[1];
		}

		return (
			<React.Fragment>
				<Helmet>
					{data && <title>{data.acf.columns[pageNum].seo_title}</title>}
					{data && <meta name="description" content={data.acf.columns[pageNum].seo_description} />}
				</Helmet>
				<Content backLink="/Services">
					<h1>{data && data.acf.columns[pageNum].main_heading}</h1>
					<h2 className="content__subheading">{data && data.acf.columns[pageNum].main_subheading}</h2>
					<div dangerouslySetInnerHTML={{__html: replaceArrow(contentPt1)}}></div>
					{contentPt2.length > 0 && <SprintColumns/>}
					<div dangerouslySetInnerHTML={{__html: replaceArrow(contentPt2)}}></div>
				</Content>
			</React.Fragment>
		);
	}
	componentDidMount() {
		this.props.getServicesContent();
		this.track();
	}
	componentDidUpdate() {
		this.track();
	}
	track = () => {
		const route = this.props.match.params.sub_content;
		const pageNum = this.state.pageTitles[route] && this.state.pageTitles[route].num;
		if (!this.tracked && this.props.content) {
			trackPage(this.props.content.acf.columns[pageNum].seo_title);
			this.tracked = true;
		}
	}
}

function SprintColumns(props) {
	return (
		<div className="sprint-columns">
			<div className="sprint-column">
				<div className="sprint-column__head">6 day sprint</div>
				<div className="sprint-column__main">
					<div className="sprint-column__intro">
						<p>This option is fast but still pretty damn thorough. Why 6 days? Because we have a day-by-day schedule and have found this is the optimal way to build & validate a prototype.</p>
					</div>
					<img className="sprint-column__arrow" src={arrow} alt=""/>
					<ul className="sprint-column__list">
						<li>Problem solving</li>
						<li>User journey mapping</li>
						<li>Idea generation</li>
						<li>Concept design & review</li>
						<li>Prototype build & validation</li>
						<li>HTML micro-prototypes</li>
						<li>Prototype finalisation & presentation</li>
					</ul>
				</div>
			</div>
			<div className="sprint-column">
				<div className="sprint-column__head">10 day sprint</div>
				<div className="sprint-column__main">
					<div className="sprint-column__intro">
						<p>This option is the best choice for slightly more complicated tasks where a more usable HTML prototype is required. It gives us more time to build & validate</p>
					</div>
					<img className="sprint-column__arrow" src={arrow} alt=""/>
					<ul className="sprint-column__list">
						<li>Brand definition</li>
						<li>Problem solving</li>
						<li>User journey mapping</li>
						<li>Idea generation</li>
						<li>Concept design & review</li>
						<li>Lo-fi prototype build & validation</li>
						<li>Hi-fi prototype build</li>
						<li>User testing of prototype</li>
						<li>Reworking & finalisation</li>
						<li>Stakeholder presentation</li>
					</ul>
				</div>
			</div>
			<div className="sprint-column">
				<div className="sprint-column__head">5 day retreat</div>
				<div className="sprint-column__main">
					<div className="sprint-column__intro">
						<p>Fancy something different?</p>
						<p>For those of you that would prefer to get away from your current working environment, forge a real team mentaltity & produce great outcomes together.</p>
					</div>
					<img className="sprint-column__arrow" src={arrow} alt=""/>
					<ul className="sprint-column__list">
						<li>AirBnb retreat location</li>
						<li>Truly collaborative process</li>
						<li>Food, drink & super fast internet</li>
						<li>Personal development for your team members</li>
						<li>Concepting & prototype build</li>
						<li>Full day facilitated user testing</li>
						<li>Finalisation of ideas & prototypes for stakeholder presentation</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

const PeopleFirstWrapper = props => <SubContent {...props}></SubContent>

export default connect(
	state => ({
		content: state.servicesContent
	}),
	dispatch => ({
		getServicesContent: () => {
			dispatch(getServicesDataAction())
		}
	})
)(PeopleFirstWrapper);
