import React, { Component } from 'react';
import { connect } from 'react-redux';
import logoMark from '../../static/logo-mark.svg';
import './loader.css';

class Loader extends Component {
    render() {
        let classes = ['loader'];
        if(this.props.loading) classes.push('show');
        return (
            <div className={classes.join(' ')}>
                <div className="loader__ring"></div>
                <div className="loader__ring"></div>
                <img className="loader__logo" src={logoMark} alt=""/>
            </div>
        );
    }
}

const LoaderWrapper = props => <Loader {...props}></Loader>

export default connect(
    state => ({
        loading: state.loading
    }),
    dispatch => ({})
)(LoaderWrapper);
